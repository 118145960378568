import * as XLSX from "xlsx";

/**
 * Export data as xlsx.
 */
export const exportData = (filename: string, data: unknown[]) => {
	const workbook = XLSX.utils.book_new();

	// const dateString = new Date().toISOString().replace(/-/g, "").replace(/:/g, "").replace(/\.\d+/, "");
	const dateString = new Date().toISOString().split("T")[0];

	// Create worksheet.
	const worksheet = XLSX.utils.json_to_sheet(data);

	// Add sheet to workbook.
	XLSX.utils.book_append_sheet(workbook, worksheet, filename);

	// Create file.
	XLSX.writeFile(workbook, `${filename}-${dateString}.xlsx`);
};

/**
 *  Export data as xlsx with header.
 * @param filename filenaname
 * @param columns column names
 * @param data array of arrays
 */
export const exportDataAoA = (filename: string, columns: string[], data: ({} | null | undefined)[][]) => {
	const worksheetData = [columns, ...data];
	const workbook = XLSX.utils.book_new();
	const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
	XLSX.utils.book_append_sheet(workbook, worksheet, filename);
	XLSX.writeFile(workbook, `${filename}-${new Date().toISOString().split("T")[0]}.xlsx`);
};
