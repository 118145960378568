import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError, AxiosInstance } from "axios";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { pathToUrl } from "../utils/pathToUrl";
import { useAxiosContext } from "./AxiosProvider";

export function useGetUILanguages() {
	const axiosInstance = useAxiosContext();
	const URL = apiRoutes.getUILanguages;
	return useQuery<Eezynet.Language[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.Language[]>(ApiRootUrl + URL);
			return data;
		},
	});
}

export function useGetSystemTranslations(languageCulture?: string) {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getAllSystemTranslations, { languageCulture: languageCulture }, true);
	return useQuery<Eezynet.Translation[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.Translation[]>(ApiRootUrl + URL);
			return data;
		},
	});
}

export const getAllEditableSystemTranslations = (axiosInstance: AxiosInstance) => {
	const URL = apiRoutes.getAllSystemTranslations;
	return axiosInstance.get<Eezynet.Translation[]>(ApiRootUrl + URL).then((response) => {
		return response.data;
	});
};

export const SaveSystemTranslations = (axiosInstance: AxiosInstance, systemtranslations: Eezynet.Translation[]) => {
	const URL = apiRoutes.saveSystemTranslation;
	axiosInstance.post(ApiRootUrl + URL, systemtranslations);
};

export const useGetSurveyTranslationKeys = (surveyId?: number) => {
	const axiosInstance = useAxiosContext();
	const enabled = surveyId !== undefined;
	const URL = enabled ? pathToUrl(apiRoutes.getSurveyTranslationKeys, { surveyid: surveyId }) : apiRoutes.getSurveyTranslationKeys;
	return useQuery<string[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<string[]>(ApiRootUrl + URL);
			return data.sort();
		},
		enabled,
	});
};

export const useGetSurveyTranslations = (surveyId?: number): UseQueryResult<Eezynet.Translation[], AxiosError> => {
	const axiosInstance = useAxiosContext();
	const enabled = surveyId !== undefined;
	const URL = enabled ? pathToUrl(apiRoutes.getSurveyTranslations, { surveyid: surveyId }) : apiRoutes.getSurveyTranslations;

	return useQuery<Eezynet.Translation[], AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Record<string, string>>(ApiRootUrl + URL);

			// Transform the key-value pairs into the Translation type
			const transformedData: Eezynet.Translation[] = Object.entries(data).map(([key, value]) => ({
				translationKey: key,
				text: value,
			}));

			return transformedData;
		},
		enabled,
	});
};

export const useGetSurveyTranslation = (translationKey: string | undefined, surveyId?: number) => {
	const axiosInstance = useAxiosContext();
	const enabled = surveyId !== undefined && translationKey !== undefined && translationKey.length > 0;
	const URL = enabled ? pathToUrl(apiRoutes.getSurveyTranslation, { surveyid: surveyId, translationkey: translationKey }) : apiRoutes.getSurveyTranslation; // palautetaan tarkoituksella koko url, jota debuggaus on helpompaa
	return useQuery<string, AxiosError>({
		queryKey: [URL],
		queryFn: async () => {
			const { data } = await axiosInstance.get<string>(ApiRootUrl + URL);
			return data;
		},
		enabled,
	});
};

// toinen vaihtoehto olisi toteuttaa tämä fiksusti hyöntäen react-queryn cachea
export const getSurveyTranslation = async (axiosInstance: AxiosInstance, translationKey: string, surveyId: number): Promise<string | undefined> => {
	const URL = pathToUrl(apiRoutes.getSurveyTranslation, {
		surveyid: surveyId,
		translationkey: translationKey,
	});
	const { data } = await axiosInstance.get<string>(ApiRootUrl + URL);
	return data !== "" ? data : undefined; // Huom! tämä on tehty siksi, että API palauttaa tuntemattomien käännösten tilalla "". olisi parempi korjata API palauttamaan undefined
};
