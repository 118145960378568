import { areArraysEqual, omitKeys } from "../utils/Helpers";

export enum SurveyElementType {
	Theme = "Theme",
	QuestionGroup = "QuestionGroup",
	Question = "Question",
}

// Muistiinpano: olisi työlästä lisätä theme, questiongroup ja question -tyyppeihin expliissittinen tieto niiden tyypistä, sillä muuten tämä tieto täytyisi tulla myös API:sta

export module Eezynet {
	export class PagedResult<T> {
		pageNumber: number = 0;
		pageSize: number = 0;
		data: T[] = [];
		totalRecords: number = 0;
		totalPages: number = 0;
		firstPage: string = "";
		lastPage: string = "";
		nextPage?: string;
		previousPage?: string;
	}

	export class Customer {
		id: number = 0;
		name: string = "";
		surveys?: Survey[];
	}

	export enum SurveyType {
		Survey = "Survey",
		Norm = "Norm",
	}

	export class Survey {
		id: number = 0;
		customerId?: number = 0;
		name: string = "";
		shortName?: string;
		structure?: Structure;
		date?: Date;
		organisations?: Organisation[] = [];
		type?: SurveyType = SurveyType.Survey; // Norm surveys function in many places as normal surveys
		norms: Norm[] = [];
		indices: Index[] = [];
		defaultNormId?: number = 0;
		languages: Language[] = [];
		usergroups?: Usergroup[] = [];
		masterLanguage?: Language;
		// käytetään tutkimusid:tä järjestysnumerona, jos tätä ei ole muuten annettu
		private _ordinal?: number | undefined = 0;
		collectionStartDate?: string = ""; // TODO: muuta Dateksi
		collectionEndDate?: string = ""; // TODO: muuta Dateksi
		parent?: Customer;
		public get ordinal(): number {
			return this._ordinal ?? this.id;
		}
		public set ordinal(value: number | undefined) {
			this._ordinal = value;
		}
		constructor(init?: Partial<Survey>) {
			Object.assign(this, init);
		}
	}

	export class Norm {
		id: number = 0;
		name: string = "";
		constructor(init?: Partial<Norm>) {
			Object.assign(this, init);
		}
	}

	export class Index {
		id: number = 0;
		name: string = "";
		translationKey?: string;
		questionIds?: number[] = []; // indexes questionids NOTE: This is a Cixtranet4 specific feature and should be removed
		scale?: Scale = undefined; // NOTE: tämä tieto ei tule API:sta! https://dev.azure.com/corporatespirit/Eezynet/_workitems/edit/3098
		scaleMin?: number;
		scaleMax?: number;
		default?: boolean;
		label?: string; // HUOM! indeksin label on 8/2024 toteutettu niin, että se on ensisijaisesti PoolIndexId ja jos tämä on tyhjä niin sitten indeksin oma ID
		constructor(initialValue?: Partial<Index>) {
			Object.assign(this, initialValue);
		}
	}

	export class Organisation {
		id: number = 0;
		name: string = "";
		rootUnit?: OrganisationUnit;
		constructor(initialValue?: Partial<Organisation>) {
			Object.assign(this, initialValue);
			if (initialValue?.rootUnit) {
				this.rootUnit = new OrganisationUnit(initialValue.rootUnit);
			}
		}
	}

	export enum AccessLevel {
		Normal = "Normal", // normal access level
		Limited = "Limited", // unit is visible but access is limited. ex. Options are not allowed for result search
		FakeUnit = "FakeUnit", // unit is fake, and exist for technical reason. ex. a Placeholder for rootunit
	}

	export class OrganisationUnit {
		id: number = 0;
		name: string = "";
		respondentCountCumulative: number = 0;
		get depth(): number {
			if (this.parentUnit) {
				return this.parentUnit.depth + 1;
			} else {
				return 0;
			}
		}
		headCount?: number;
		// count the headcount of the children as a recursive function
		get headCountCumulative(): number {
			return (this.headCount ?? 0) + (this.childUnits?.reduce((acc, child) => acc + child.headCountCumulative, 0) ?? 0);
		}
		get headCountTotal(): number {
			return (this.headCount ?? 0) + this.headCountCumulative;
		}
		identifier: string = "";
		ordinal?: number;
		parentId?: number;
		personnelCount?: number;
		value?: number;
		childUnits: OrganisationUnit[] = [];
		parentUnit?: OrganisationUnit;
		normId?: number;
		accessLevel?: AccessLevel;
		// create function that iterates through the childunits and returns the unit with the given id
		getChildUnitById(id: number): OrganisationUnit | undefined {
			if (this.id === id) {
				return this;
			} else {
				for (const childUnit of this.childUnits) {
					const childUnitFound = childUnit.getChildUnitById(id);
					if (childUnitFound) {
						return childUnitFound;
					}
				}
			}
		}
		getFirstChildUnitByAccessLevel(accessLevel: AccessLevel): OrganisationUnit | undefined {
			if (this.accessLevel === accessLevel) {
				return this;
			} else {
				for (const childUnit of this.childUnits) {
					const childUnitFound = childUnit.getFirstChildUnitByAccessLevel(accessLevel);
					if (childUnitFound) {
						return childUnitFound;
					}
				}
			}
		}
		constructor(init?: Partial<OrganisationUnit>) {
			const { depth, headCountCumulative, headCountTotal, ...tempUnit } = init as OrganisationUnit;
			Object.assign(this, { ...tempUnit, childUnits: tempUnit?.childUnits?.map((childUnit) => new OrganisationUnit(childUnit)) });
		}
	}

	export type Translation = {
		id?: number;
		translationKey: string;
		languageId?: number;
		text: string;
	};

	export class Language {
		id: number = 0;
		abbreviation: string = "";
		culture: string = "";
	}

	export class Usergroup {
		id: number = 0;
		surveyId: number = 0;
		externalUsergroupType?: string; // NOTE: This is a Cixtranet4 specific feature and should be removed
		externalUsergroupId?: number; // NOTE: This is a Cixtranet4 specific feature and should be removed
		removedFromExternal?: boolean;
		name: string = "";
		usergroupPermissions: UsergroupPermission[] = [];
		constructor(init?: Partial<Usergroup>) {
			Object.assign(this, init);
		}
	}

	export class UsergroupPermission {
		id: number = 0;
		surveyUsergroupId: number = 0;
		assetType?: string;
		assetId?: number = 0;
		permission?: boolean;
		allowDistribution?: boolean = true;
		//function that creates a string hash
		getHash = () => {
			return this.id?.toString() + this.surveyUsergroupId?.toString() + (this.assetType ?? "") + (this.assetId ?? "");
		};
		constructor(init?: Partial<UsergroupPermission>) {
			Object.assign(this, init);
		}
	}

	// -------------- STRUCTURE -------------
	export class Structure {
		themes: Theme[] = [];
		constructor(init?: Partial<Structure>) {
			Object.assign(this, init);
		}
	}

	export class surveyElementBase {
		id: number = 0;
		translationKey?: string;
		name: string = "";
		ordinal: number = 0;
		constructor(initialValue?: Partial<surveyElementBase>) {
			Object.assign(this, initialValue);
		}
		parent?: Theme | QuestionGroup;

		toJSON() {
			const { parent, ...rest } = this;
			return Object.keys(rest).reduce((acc, key) => {
				const value = (rest as any)[key]; // TypeScript type assertion
				if (value && typeof value.toJSON === "function") {
					acc[key] = value.toJSON();
				} else {
					acc[key] = value;
				}
				return acc;
			}, {} as any);
		}
	}

	export class Theme extends surveyElementBase {
		externalPoolId?: number; // NOTE: This is a Cixtranet4 specific feature and should be removed
		private _questionsGroups: QuestionGroup[] = [];
		public get questionGroups(): QuestionGroup[] {
			return this._questionsGroups;
		}
		public set questionGroups(value: QuestionGroup[]) {
			this._questionsGroups = value;
			if (this._questionsGroups) this._questionsGroups.forEach((q) => (q.parent = this)); // add parent for each questionGroup
		}
		// type: ItemTypes.THEME;
		constructor(initialValue?: Partial<Theme>) {
			super(initialValue);
			Object.assign(this, initialValue);
			this.questionGroups = initialValue?.questionGroups ?? []; // jotta parent saadaan asetettua
		}
	}

	export enum QuestionGroupType {
		Background = "Background", // BACKGROUND = 1000,
		Numeric = "Numeric", // NUMERIC = 1001,
		OpenEnded = "OpenEnded", // OPENENDED = 1002,
		Special = "Special", // SPECIAL = 1003,
		ContentAverage = "ContentAverage", // CONTENTAVERAGE = 9996,
		MainFinding = "MainFinding", // MAINFINDING = 9997,
		Statistics = "Statistics", // STATISTICS = 9998,
		Index = "Index", // INDEX = 9999,
	}

	// NOTE: This is a Cixtranet4 specific feature and should be removed
	export enum QuestionGroupC4PoolId {
		JOBMOTIVATION = 1000084,
		EMPOWERMENT = 1000085,
		PREREQUISITES = 1000086,
		COMMUNICATION = 1000087,
		MANAGERIALWORK = 1000088,
		EFFECTIVENESS = 1000089,
		EMPLOYERIMAGE = 1000090,
		LEADERSHIPCULTURE = 1000091,
		OPERATIVECULTURE = 1000092,
		COMMITMENT = 1000045,
		LEADERSHIP = 1000046,
		PERFORMANCE = 1000047,
		OWNWORK = 1000048,
		UNIT = 1000049,
		COMPANY = 1000050,
	}

	export class QuestionGroup extends surveyElementBase {
		type?: QuestionGroupType;
		externalId?: string; // NOTE: This is a Cixtranet4 specific feature and should be removed
		externalPoolId?: number; // NOTE: This is a Cixtranet4 specific feature and should be removed
		scale: Scale = new Scale();
		private _questions: Question[] = [];
		public get questions(): Question[] {
			return this._questions;
		}
		public set questions(value: Question[]) {
			this._questions = value;
			if (this._questions) this._questions.forEach((q) => (q.parent = this)); // add parent for each question
		}
		parent?: Theme;
		// eslint-disable-next-line @typescript-eslint/no-useless-constructor
		constructor(initialValue?: Partial<QuestionGroup>) {
			super(initialValue);
			Object.assign(this, initialValue);
			this.questions = initialValue?.questions ?? []; // jotta parent saadaan asetettua
		}
	}

	export enum QuestionType {
		BackgroundSingle = "BackgroundSingle", // BACKGROUNDSINGLE = 1000,
		BackgroundMulti = "BackgroundMulti", // BACKGROUNDMULTI = 1001,
		NumericSingle = "NumericSingle", // NUMERICSINGLE = 1002,
		NumericMulti = "NumericMulti", // NUMERICMULTI = 1003,
		Open = "Open", // OPEN = 1004,
		Meta = "Meta", // META = 1005, // TODO: ei käytetä missään, tulee vanhasta Cixtrasta
		UnitSingle = "UnitSingle", // UNITSINGLE = 1006, // TODO: ei käytetä missään, tulee vanhasta Cixtrasta
		Importance = "Importance", // IMPORTANCE = 1007, // TODO: tälle ei ole tehty mitään muista poikkeavaa toteutusta, tarvitseeko?
		UnitMulti = "UnitMulti", // UNITMULTI = 1008, // TODO: ei käytetä missään, tulee vanhasta Cixtrasta
		NPS = "NPS", // NPS = 1009,
		ContentAverage = "ContentAverage", // CONTENTAVERAGE = 9996,
		MainFindings = "MainFindings", // MAINFINDING = 9997, // TODO: käytetäänkö missään?
		Statistics = "Statistics", // STATISTICS = 9998, // TODO: käytetäänkö missään?
		Index = "Index", // INDEX = 9999,
	}

	export class Question extends surveyElementBase {
		private _scale?: Scale | undefined;
		public get scale(): Scale | undefined {
			if (this._scale === undefined && this.parent !== undefined) return this.parent.scale;
			return this._scale;
		}
		public set scale(value: Scale | undefined) {
			this._scale = value;
		}
		type?: QuestionType;
		parent?: QuestionGroup;
		label: string = "";
		isNegative: boolean = false;
		crosstabulation?: boolean = false; // can this question be used in crosstabulation. NOTE: This is a Cixtranet4 specific feature and should be removed
		filter?: boolean = false; // can this question be used in filtering. NOTE: This is a Cixtranet4 specific feature and should be removed
		topBottom?: boolean = false; // can this question be used in top/bottom. NOTE: This is a Cixtranet4 specific feature and should be removed
		topChanges?: boolean = false; // can this question be used in top changes. NOTE: This is a Cixtranet4 specific feature and should be removed
		internal?: boolean = false; // can this question be used by non internal user. NOTE: This is a Cixtranet4 specific feature and should be removed
		constructor(initialValue?: Partial<Question>) {
			super(initialValue);
			Object.assign(this, initialValue);
		}
	}

	export class Scale {
		id: number = 0;
		name?: any;
		scaleOptions: ScaleOption[] = [];
		// get the smallest value of the scale
		get minValue(): number | undefined {
			// if (this.scaleOptions.length === 0) { return -999 }
			return this.scaleOptions.filter((x) => !x.noanswer).reduce((acc, option) => Math.min(acc, option.value), Number.MAX_VALUE);
		}
		// get the largest value of the scale
		get maxValue(): number | undefined {
			// if (this.scaleOptions.length === 0) { return 999 }
			return this.scaleOptions.filter((x) => !x.noanswer).reduce((acc, option) => Math.max(acc, option.value), Number.MIN_VALUE);
		}
		constructor(initialValue?: Partial<Scale>) {
			Object.assign(this, initialValue);
		}
	}

	export class ScaleOption {
		id: number = 0;
		stringId: string = "";
		name: string = "";
		value: number = 0;
		noanswer: boolean = false;
		color: string = "";
		favorable: number = 0;
		ordinal: number = 0;
		translationKey?: string;
		constructor(initialValue?: Partial<ScaleOption>) {
			Object.assign(this, initialValue);
		}
	}

	export type QuestionCount = {
		questionId?: number;
		respondentCount?: number;
	};

	//#region Result

	export class OptionCount {
		/// <summary>
		/// Numerical value of the option.
		/// This can be a number or NaN whict means that this option doesn't contibute to any calculations (e.g. average)
		/// </summary>
		v: number | null = null;
		/// <summary>
		/// Count of answers to this option
		/// </summary>
		n: number = 0;
	}

	export class QuestionResultBase {
		respondentGroupHash: string = "";
		questionId: number = 0;
		responseCount?: number;
		constructor(initialValue?: Partial<QuestionResultBase>) {
			Object.assign(this, initialValue);
		}
	}

	export class QuestionResultNumeric extends QuestionResultBase {
		average?: number;
		standardDeviation?: number;
		responseOptionCounts?: OptionCount[] | null;
	}

	export class QuestionResultText extends QuestionResultBase {
		textualAnswers?: string[] | null;
	}

	//#endregion Result

	//#region Mainfindings

	export class FindingsViewModel {
		surveyId?: number;
		organisationRule?: OrganisationRule;
		questionIds: number[] = [];
		normRule?: NormRule;
		filterQuestionRules: QuestionAnswerPairInt[] = [];

		constructor(initialValue?: Partial<FindingsViewModel>) {
			Object.assign(this, initialValue);
		}
	}
	export class MainFinding {
		strengthFindings?: Finding[] | null;
		issueFindings?: Finding[] | null;
	}
	export class Finding {
		id: number = 0;
		ordinal: number = 0;
	}

	//#endregion Mainfindings

	export class RequestViewModel {
		currentSurveyId?: number;
		questionIds: number[] = [];
		respondentGroups: RespondentGroup[] = [new RespondentGroup()];
		simulationParams?: SimulationParams;
		contentAverageId?: number;
	}
	export class RequestIndexViewModel {
		currentSurveyId?: number;
		indexIds: number[] = [];
		respondentGroups: RespondentGroup[] = [new RespondentGroup()];
		simulationParams?: SimulationParams;
	}
	export class RequestContentGroupViewModel {
		currentSurveyId?: number;
		questionGroups: ContentGroup[] = [];
		respondentGroups: RespondentGroup[] = [new RespondentGroup()];
		simulationParams?: SimulationParams;
	}
	export type ContentGroup = {
		groupId?: number;
		questionIds?: number[];
		title?: string;
		translationKey?: string;
	};

	//#region Rules
	export enum RuleType {
		Unknown = "Unknown", // HUOM! Tätä ei ole implementoitu backend:ssä ollenkaan
		QuestionAnswerPairInt = "QuestionAnswerPairInt",
		QuestionAnswerPairString = "QuestionAnswerPairString",
		OrganisationRule = "OrganisationRule",
		SurveyRule = "SurveyRule",
		NormRule = "NormRule",
		RespondentRule = "RespondentRule",
	}
	export interface iRule {
		type: RuleType;
	}

	class QuestionAnswerPair<T> implements iRule {
		type: RuleType = RuleType.Unknown;
		questionId: number = 0;
		answer: T = {} as T;
		forced?: boolean = false;
		constructor(initialValue?: Partial<QuestionAnswerPair<T>>) {
			Object.assign(this, initialValue);
		}
	}
	export class QuestionAnswerPairInt extends QuestionAnswerPair<number> implements iRule {
		type: RuleType = RuleType.QuestionAnswerPairInt;
	}

	export class QuestionAnswerPairString extends QuestionAnswerPair<string> implements iRule {
		type: RuleType = RuleType.QuestionAnswerPairString;
	}

	export class OrganisationRule implements iRule {
		type: RuleType = RuleType.OrganisationRule;
		organisationId: number = 0;
		unitId: number = 0;
		fromSurveyId?: number;
		constructor(initialValue?: Partial<OrganisationRule>) {
			Object.assign(this, initialValue);
		}
	}

	export class SurveyRule implements iRule {
		type: RuleType = RuleType.SurveyRule;
		constructor(initialValue?: Partial<SurveyRule>) {
			Object.assign(this, initialValue);
		}
	}

	export class NormRule implements iRule {
		type: RuleType = RuleType.NormRule;
		normId?: number = undefined; // jos tämä on määritelty, niin käytetään kyseistä normia
		unitId?: number = undefined; // jos tämä on määritelty, niin etsitään kyseinen unit respondentgroupin surveyid:n mukaisesta tukimuksesta ja etsitään tämän unitin normi
		// jos kumpaakaan, normId:tä tai unitId:tä ei ole määritelty, niin käytetään respondentgroupin surveyid:n mukaisen tukimuksesta löytyvää normia
		constructor(initialValue?: Partial<NormRule>) {
			Object.assign(this, initialValue);
		}
	}
	//#endregion Rules

	//#region RespondentGroup
	export enum AdditiveNamingRules {
		No = "No",
		Yes = "Yes",
		OnlyIfNoOtherRules = "OnlyIfNoOtherRules",
	}

	export class NamingConventions {
		showQuestion: boolean = false;
		showAdditiveRules: AdditiveNamingRules = AdditiveNamingRules.OnlyIfNoOtherRules;
		showType: boolean = false;
		showSurveyName: boolean = false;
		showOnlyForcedQuestion?: boolean = false;
		showUnitName: boolean = false;
		showNormName: boolean = false;
		public constructor(init?: Partial<NamingConventions>) {
			Object.assign(this, init);
		}
	}

	export enum RespondentGroupType {
		Normal = "Normal",
		NormBased = "Normbased",
	}

	export class RespondentGroup {
		surveyId?: number;
		hash?: string;
		additiveRules?: Eezynet.iRule[] = [];
		delimitingRules?: Eezynet.iRule[] = [];
		removingRules?: Eezynet.iRule[] = [];
		respondentCount?: number | null;
		namingConventions?: NamingConventions;
		private _name?: string;
		private _shortName?: string;
		comparisonGroup?: RespondentGroup;

		public constructor(init?: Partial<RespondentGroup>) {
			Object.assign(this, init);
		}

		// Creates the name based on the rules
		set name(value: string | undefined) {
			this._name = value;
		}
		get name(): string {
			return this._name ? this._name.trim() : "?";
		}

		set shortName(value: string | undefined) {
			this._shortName = value;
		}

		get shortName(): string {
			return this._shortName ? this._shortName.trim() : "?";
		}

		get groupType(): RespondentGroupType {
			if (this.additiveRules && this.additiveRules.some((rule) => rule instanceof Eezynet.NormRule)) {
				return RespondentGroupType.NormBased;
			}
			return RespondentGroupType.Normal;
		}

		isSameGroupAs(other: RespondentGroup): boolean {
			if (this.surveyId !== other.surveyId) return false;
			if (areArraysEqual(this.additiveRules, other.additiveRules)) return false;
			if (areArraysEqual(this.delimitingRules, other.delimitingRules)) return false;
			if (areArraysEqual(this.removingRules, other.removingRules)) return false;
			//check if the comparison group is the same
			if (this.comparisonGroup === undefined && other.comparisonGroup !== undefined) return false;
			if (this.comparisonGroup !== undefined && other.comparisonGroup === undefined) return false;
			if (this.comparisonGroup && other.comparisonGroup && this.comparisonGroup.isSameGroupAs(other.comparisonGroup) === false) return false;
			if (JSON.stringify(this.namingConventions) !== JSON.stringify(other.namingConventions)) return false;
			return true;
		}

		/**
		 * Returns a sanitized version of the RespondentGroup object, omitting certain keys that should not be used for calculating respondentGroup hash (in the frontend)
		 * @returns {Partial<RespondentGroup>} A partial RespondentGroup object with omitted keys.
		 */
		get sanitized(): Partial<RespondentGroup> {
			const keysToOmit: string[] = ["_name", "name", "respondentCount", "hash"];
			const sanitizedGroup = {
				...omitKeys(this, keysToOmit as any),
				comparisonGroup: this.comparisonGroup ? omitKeys(this.comparisonGroup, keysToOmit as any) : undefined,
			};
			return sanitizedGroup as Partial<RespondentGroup>;
		}
	}

	export class ExtendedRespondentGroup extends RespondentGroup {
		externalId?: string;
	}
	//#endregion RespondentGroup

	//#region Authentication

	export class ChangePasswordRequest {
		OtpToken: string = "";
		EmailAddress: string = "";
		NewPassword: string = "";
		ConfirmPassword: string = "";
	}

	//#endregion Authentication

	//#region Errors
	export type SystemError = {
		id: number;
		userId: number | null;
		customerId: number | null;
		surveyId: number | null;
		dateTime: string;
		message: string;
		stackTrace: string;
	};
	//#endregion Errors

	//#region Statistic

	export type Statistic = {
		surveyId?: number;
		organisationId?: number;
		rootUnitHeadCountCumulative?: number;
		rootUnitRespondentCountCumulative?: number;
		unitHeadCountCumulative?: number;
		unitRespondentCountCumulative?: number;
	};

	//#endregion Statistic

	//#region Helpers

	export class SimulationParams {
		Usergroups?: Usergroup[];
	}

	//#endregion
}
