import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { NavLink, Link as RouterLink } from "react-router-dom";
import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import CloseIcon from "@mui/icons-material/Close";
import LanguageIcon from "@mui/icons-material/Language";
import Logout from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	Link,
	ListItemIcon,
	Menu,
	MenuItem,
	OutlinedInput,
	SxProps,
	Theme,
	Toolbar,
	Tooltip,
	useTheme,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useAppContext } from "../../AppContext";
import { usePageLayoutContext } from "../../PageLayoutProvider";
import { Path, getRestrictedMainMenuItems, useTranslatedPaths } from "../../Routes";
import { useGetSurvey } from "../../api/Surveys";
import { useGetUILanguages } from "../../api/Translations";
import { User, UserType } from "../../models/User";
import i18next from "../../translations/i18n";
import LoggedInName from "../authentication/LoggedInName";
import { SignInButton } from "../authentication/SignInButton";
import { ErrorFallback } from "../helpers/ErrorBoundary";

interface UserAvatarProps {
	user?: User;
}

const UserAvatar = ({ user }: UserAvatarProps) => {
	// If a user avatar is available, return an img tag with the pic
	return (
		<>
			{user?.avatar ? (
				<Avatar
					sx={{ height: "2.5rem", width: "2.5rem", border: "0.5px solid rgba(128,128,128,0.8)" }}
					src={user.avatar.toString()}
					alt={user?.name ?? ""}
				></Avatar>
			) : (
				<Avatar sx={{ width: 32, height: 32 }}>
					<LoggedInName onlyInitials={true} />
				</Avatar>
			)}
		</>
	);
};

interface TopMenuProps {
	sx: SxProps<Theme>;
}
const TopMenu = (props: TopMenuProps) => {
	const { sx } = props;
	const { handleDrawerToggle, topMenuHeight, isMobile } = usePageLayoutContext();

	const theme = useTheme();
	const {
		selectedSurveyId,
		selectedUiLanguage,
		setSelectedUiLanguage,
		selectedSurveyLanguage,
		setSelectedSurveyLanguage,
		isSeperateUiLanguage,
		setIsSeperateUiLanguage,
	} = useAppContext();
	const { data: survey, isLoading: surveyIsLoading } = useGetSurvey(selectedSurveyId, !!selectedSurveyId);
	//#region Authentication
	const { inProgress } = useMsal();
	const { user, signOut } = useAppContext();
	//#endregion Authentication

	const { t } = useTranslation();
	const paths = useTranslatedPaths();
	const mainMenuItems: Path[] = getRestrictedMainMenuItems(paths, user);

	//#endregion

	//#region UserMenu
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const [userMenuOpen, setUserMenuOpen] = React.useState(false);
	const [languageMenuOpen, setLanguageMenuOpen] = React.useState(false);
	const [uiLanguage, setUILanguage] = React.useState<string>("");
	const [surveyLanguage, setSurveyLanguage] = React.useState<string>("");

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
		setUserMenuOpen(true);
	};
	const handleOpenLanguageMenu = (event: React.MouseEvent<HTMLElement>) => {
		setLanguageMenuOpen(true);
	};
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
		setUserMenuOpen(false);
	};
	const handleCloseLanguageMenu = () => {
		setLanguageMenuOpen(false);
	};
	const handleLanguageSeparationCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setIsSeperateUiLanguage(event.target.checked);
	};
	const handleUILanguageChange = (event: SelectChangeEvent<typeof uiLanguage>) => {
		setUILanguage(event.target.value);
		i18next.changeLanguage(event.target.value);
		setSelectedUiLanguage(event.target.value);
	};
	const handleSurveyLanguageChange = (event: SelectChangeEvent<typeof surveyLanguage>) => {
		setSurveyLanguage(event.target.value);
		setSelectedSurveyLanguage(event.target.value);
		if (!isSeperateUiLanguage) setSurveyLanguageLink(event.target.value);
	};
	const setSurveyLanguageLink = (selectedSurveyLanguage: typeof surveyLanguage) => {
		//Hoidetaan tutkimuksen ja UI kielten linkitys jos kielivalinnassa ei erotettuna kieliä.
		//TODO MISA halutaanko oikea fallback logiikka vai onko turvallisempi palata englantiin?
		const languageTwin = uiLanguages.data?.find((x) => x.culture.startsWith(selectedSurveyLanguage));
		if (languageTwin != null) {
			setUILanguage(languageTwin.culture);
			i18next.changeLanguage(languageTwin.culture);
			setSelectedUiLanguage(languageTwin.culture);
		}
		if (languageTwin == null) {
			setUILanguage(uiLanguages.data?.at(0)?.culture ?? "en-gb");
			i18next.changeLanguage(uiLanguages.data?.at(0)?.culture ?? "en-gb");
			setSelectedUiLanguage(uiLanguages.data?.at(0)?.culture ?? "en-gb");
		}
	};

	const { isTestingExternalUser } = useAppContext();

	// create type extendedPath that has also property func
	type userMenuItemType = Path & { func?: () => void };

	const userMenuItems: userMenuItemType[] = [
		paths.profile,
		{ ...paths.settings, restrictToUserTypes: isTestingExternalUser ? undefined : paths.settings.restrictToUserTypes },
		paths.translations,
		paths.errors,
		{ title: t("menu_logout"), icon: <Logout fontSize="small" />, func: signOut, path: "", element: <></> },
	];
	const UserMenuItem = (menuItem: userMenuItemType) => {
		const allowExternal =
			menuItem.restrictToUserTypes === undefined || menuItem.restrictToUserTypes?.length === 0 || menuItem.restrictToUserTypes?.includes(UserType.Normal);
		return (
			<MenuItem
				key={menuItem.title}
				onClick={menuItem.func ?? handleCloseUserMenu}
				sx={{
					textAlign: "center",
					textDecoration: "none !important",
					color: allowExternal ? theme.palette.text.primary : theme.palette.secondary.main,
				}}
			>
				{menuItem.icon && <ListItemIcon>{menuItem.icon}</ListItemIcon>}
				{menuItem.title}
			</MenuItem>
		);
	};

	const SurveyLanguageItem = () => {
		if (surveyIsLoading || survey == null) return null;
		return survey?.languages.map((el, i: number) => (
			<MenuItem id={el.culture} key={i} value={el.culture}>
				{el.abbreviation}
			</MenuItem>
		));
	};

	const RegionLogo = () => {
		return (
			<Box sx={{ flex: 1, display: "flex", justifyContent: { xs: "center", md: "flex-start" } }}>
				<Link component={RouterLink} to="/" color="inherit" variant="h5" sx={{ textDecoration: "none", fontWeight: "700" }}>
					CIXTRA
				</Link>
			</Box>
		);
	};

	const uiLanguages = useGetUILanguages();

	//#endregion UserMenu

	return (
		<AppBar position="fixed" sx={{ ...sx, px: "24px" }}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<Toolbar disableGutters sx={{ height: topMenuHeight + "px" }}>
					{/* Logo isolla näytöllä */}
					{!isMobile && <RegionLogo />}
					<AuthenticatedTemplate>
						{/* Hamburgermenu on mobile */}
						<Box sx={{ flex: 1, display: { xs: "flex", md: "none" } }}>
							<IconButton
								aria-label="hamburger-menu"
								//aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleDrawerToggle}
								color="inherit"
								sx={{ p: 0 }}
							>
								<MenuIcon fontSize="large" />
							</IconButton>
						</Box>

						{isMobile && <RegionLogo />}

						{/* Päävalikko */}
						<Box
							sx={{
								flex: 1,
								justifyContent: "center",
								display: { xs: "none", md: "flex" },
								minHeight: "inherit",
							}}
						>
							{mainMenuItems.map((item, i) => (
								<NavLink
									to={item.path}
									key={"l" + i}
									style={({ isActive }) => ({
										borderBottom: isActive ? "4px solid #FFF" : "inherit",
										textDecoration: "none" /*height: "2.5rem"*/,
										display: "flex",
										alignItems: "center",
										color: isActive ? "#FFF" : theme.palette.neutral[200],
									})}
								>
									<Button
										sx={{
											display: "block",
											color: item.restrictToUserTypes?.length ? theme.palette.secondary.main : "inherit",
										}}
									>
										{item.title}
									</Button>
								</NavLink>
							))}
						</Box>
						{
							//#endregion autentikoidulle näytettävät
						}
						<Box sx={{ flex: 1, justifyContent: "center", textAlign: "end" }}>
							<Tooltip title="Language">
								<IconButton id="lanugage-button" onClick={handleOpenLanguageMenu}>
									<LanguageIcon htmlColor={theme.palette.neutral[200]} />
								</IconButton>
							</Tooltip>
							{
								//#region avatar
							}
							<Tooltip title="Open settings">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
									<UserAvatar user={user} />
								</IconButton>
							</Tooltip>
							<Dialog disableEscapeKeyDown open={languageMenuOpen} onClose={handleCloseLanguageMenu}>
								<DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
									Select language
									<IconButton onClick={handleCloseLanguageMenu}>
										<CloseIcon />
									</IconButton>
								</DialogTitle>
								<DialogContent>
									<Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
										<FormControl sx={{ m: 1, minWidth: 120 }}>
											<InputLabel id="surveylng-dialog-select-label">Survey</InputLabel>
											{
												//Tutkimuksen kieli
											}
											<Select
												labelId="surveylng-dialog-select-label"
												id="surveylng-dialog-select"
												value={selectedSurveyLanguage}
												onChange={handleSurveyLanguageChange}
												input={<OutlinedInput label="Survey" />}
											>
												{SurveyLanguageItem()}
											</Select>
										</FormControl>
										<FormControlLabel
											control={<Checkbox checked={isSeperateUiLanguage} onChange={handleLanguageSeparationCheckbox} />}
											label="Separate UI language"
										/>
										{isSeperateUiLanguage && ( //Kieltenerotus-checkbox
											<FormControl sx={{ m: 1, minWidth: 120 }}>
												<InputLabel id="uilng-dialog-select-label">UI</InputLabel>
												{
													//UIn kieli
												}
												<Select
													labelId="uilng-dialog-select-label"
													id="uilng-dialog-select"
													value={selectedUiLanguage}
													onChange={handleUILanguageChange}
													input={<OutlinedInput label="UI" />}
												>
													{uiLanguages.data?.map((el, i: number) => (
														<MenuItem id={el.culture} key={i} value={el.culture}>
															{el.abbreviation}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										)}
									</Box>
								</DialogContent>
								<DialogActions>
									<Button onClick={handleCloseLanguageMenu}>{t("close")}</Button>
								</DialogActions>
							</Dialog>
							{
								//#region avatar
							}
							{
								//#region oikean yläkulman valikko
							}
							<Menu
								sx={{ mt: topMenuHeight - 20 + "px" }}
								// id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={userMenuOpen}
								onClose={handleCloseUserMenu}
							>
								{userMenuItems.map((menuItem, i: number) => {
									const isAllowed =
										!menuItem.restrictToUserTypes ||
										menuItem.restrictToUserTypes?.length === 0 ||
										(user?.userType && menuItem.restrictToUserTypes?.includes(user.userType));
									if (!isAllowed) return null;
									return menuItem.path ? (
										<Link component={RouterLink} to={menuItem.path} key={i} variant="h6">
											{UserMenuItem(menuItem)}
										</Link>
									) : (
										<span key={i}>{UserMenuItem(menuItem)}</span>
									);
								})}
							</Menu>

							{
								//#endregion oikean yläkulman valikko
							}
						</Box>
					</AuthenticatedTemplate>
					<UnauthenticatedTemplate>
						{/* // jos ei ole autentikoitunut
                        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two */}
						{inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect && (
							<>
								<Box sx={{ flexGrow: 1 }} />
								<Box sx={{ flexGrow: 0 }}>
									<SignInButton />
								</Box>
							</>
						)}
					</UnauthenticatedTemplate>
				</Toolbar>
			</ErrorBoundary>
		</AppBar>
	);
};
export default TopMenu;
