import { QueryClient, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import qs from "qs";
import { AxiosError, AxiosInstance } from "axios";
import { useAppContext } from "../AppContext";
import { ApiRootUrl, apiRoutes } from "../Routes";
import { Eezynet } from "../models/Eezynet";
import { clearHtml } from "../utils/Helpers";
import { pathToUrl } from "../utils/pathToUrl";
import { useAxiosContext } from "./AxiosProvider";

export const useGetSurvey = (id: number | undefined, enabled: boolean = true) => {
	const { selectedSurveyLanguage, simulationParams } = useAppContext();
	return useGetSurveyWithLanguageId(id, selectedSurveyLanguage, enabled, simulationParams);
};

// idea is that this function is used from AppContext it self, when the useAppContext is not available
export const useGetSurveyWithLanguageId = (
	id: number | undefined,
	selectedSurveyLanguage: string | undefined,
	enabled: boolean = true,
	simulationParams: Eezynet.SimulationParams | undefined = undefined,
) => {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getSurveyById, { id }, false, !!id);

	let simulateUgIds: number[] = [];
	if (simulationParams) {
		simulationParams.Usergroups?.forEach((ug) => {
			const ugId = ug && ug.id > 0 ? ug.id : ug?.externalUsergroupId;
			if (ugId) simulateUgIds.push(ugId);
		});
	}
	const params = simulateUgIds ? { UsergroupsIds: simulateUgIds } : {};

	return useQuery<Eezynet.Survey, AxiosError>({
		queryKey: [URL, selectedSurveyLanguage],
		queryFn: async () => {
			//const { data } = await axiosInstance.get<Eezynet.Survey>(ApiRootUrl + URL, { params });
			const { data } = await axiosInstance.get<Eezynet.Survey>(ApiRootUrl + URL, {
				params,
				paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
			});
			return new Eezynet.Survey({
				...data,
				structure: new Eezynet.Structure({
					themes: data.structure?.themes.map(
						(t) =>
							new Eezynet.Theme({
								...t,
								name: clearHtml(t.name),
								questionGroups: t.questionGroups.map(
									(qgrp) =>
										new Eezynet.QuestionGroup({
											...qgrp,
											name: clearHtml(qgrp.name),
											questions: qgrp.questions.map(
												(q) =>
													new Eezynet.Question({
														...q,
														name: clearHtml(q.name),
														scale: new Eezynet.Scale({
															...q.scale,
															scaleOptions: q.scale?.scaleOptions.map(
																(so) => new Eezynet.ScaleOption({ ...so, name: clearHtml(so.name) }),
															),
														}),
													}),
											),
										}),
								),
							}),
					),
				}),
				indices: data.indices.map(
					(i) =>
						new Eezynet.Index({
							...i,
							label:
								i.label && i.label !== "0" // jos label on null tai "0", käytetään id:tä
									? i.label
									: i.id.toString(),
						}),
				),
			});
		},
		enabled: enabled && !!id,
	});
};

export const clearCacheForSurvey = (
	queryClient: QueryClient,
	surveyId: number | undefined,
	isTestingExternalUser: boolean = false,
	selectedSurveyLanguage: string | undefined,
) => {
	if (!surveyId) return;
	const URL = pathToUrl(apiRoutes.getSurveyById, { id: surveyId });
	const queryKey = [URL, selectedSurveyLanguage];

	// Invalidate a specific query by queryKey
	queryClient.invalidateQueries({ queryKey });
};

export const useGetSurveysForCustomerInfinite = (customerId: number, pageSize: number = 100) => {
	const { selectedSurveyLanguage } = useAppContext();
	const axiosInstance = useAxiosContext();
	return useInfiniteQuery<Eezynet.PagedResult<Eezynet.Survey>, AxiosError>({
		queryKey: ["useGetSurveysForCustomerInfinite", customerId, pageSize, selectedSurveyLanguage],
		initialPageParam: 0,
		queryFn: async ({ pageParam }) => {
			const pageNumber = typeof pageParam === "number" ? pageParam : 0;
			const URL = ApiRootUrl + pathToUrl(apiRoutes.getSurveysByCustomerId, { id: customerId, pageNumber, pageSize: pageSize }, false, !!customerId);
			const { data } = await axiosInstance.get<Eezynet.PagedResult<Eezynet.Survey>>(URL);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage.pageNumber < lastPage.totalPages ? lastPage.pageNumber + 1 : null;
		},
	});
};

export function useGetSurveysByCustomerId({
	customerId,
	pageNumber = 0,
	pageSize = 9999,
	enabled = true,
}: {
	customerId: number | undefined;
	pageNumber?: number;
	pageSize?: number;
	enabled?: boolean;
}) {
	const { selectedSurveyLanguage } = useAppContext();
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getSurveysByCustomerId, { id: customerId, pageNumber: pageNumber, pageSize: pageSize }, false, !!customerId);
	const params = { pageNumber, pageSize };
	return useQuery<Eezynet.PagedResult<Eezynet.Survey>, AxiosError>({
		queryKey: [URL, selectedSurveyLanguage, params],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.PagedResult<Eezynet.Survey>>(ApiRootUrl + URL);
			return data;
		},
		// keepPreviousData: true, // ei toiminut kuten kuvittelin (vika oli ehkä QueryKey:ssä) täytyy testata myöhemmin. tilalle kuitenkin useGetSurveysForCustomerInfinite
		enabled,
	});
}

export function useGetPreviousSurveys({ surveyId }: { surveyId: number | undefined }) {
	const { selectedSurveyLanguage } = useAppContext();
	const axiosInstance = useAxiosContext();
	// const URL = !!surveyId ? pathToUrl(apiRoutes.getPreviousSurveys, { surveyid: surveyId }) : ""; // ei toimi jos surveyId on null tai undefined
	const URL = surveyId ? pathToUrl(apiRoutes.getPreviousSurveys, { surveyid: surveyId }) : apiRoutes.getPreviousSurveys; // HUOM! palautetaan tarkoituksella url, josta näkee että parametri puuttuu
	return useQuery<Eezynet.Survey[], AxiosError>({
		queryKey: [URL, selectedSurveyLanguage],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.Survey[]>(ApiRootUrl + URL);
			// add property type = Eezynet.SurveyType.Survey to ones missing this information
			data.filter((s) => !s.type).forEach((s) => {
				s.type = Eezynet.SurveyType.Survey;
			});
			return data;
		},
		enabled: !!surveyId,
	});
}

export function useResetSurveyCache(surveyId: number, enabled: boolean = true) {
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.resetSurveyCache, { surveyid: surveyId });
	const params = {};
	return useQuery<boolean, AxiosError>({
		queryKey: [URL, params],
		queryFn: async () => {
			const { data } = await axiosInstance.get<boolean>(ApiRootUrl + URL, params);
			return data;
		},
		enabled,
	});
}

// function that gets allthe organisations of a survey
export function useGetOrganisationsBySurveyId(surveyId: number | undefined, enabled: boolean = true) {
	const { selectedSurveyLanguage } = useAppContext();
	const axiosInstance = useAxiosContext();
	const URL = pathToUrl(apiRoutes.getOrganisationsBySurveyId, { surveyid: surveyId }, false, !!surveyId);
	const params = { pageSize: 9999 };
	return useQuery<Eezynet.PagedResult<Eezynet.Organisation>, AxiosError>({
		queryKey: [URL, selectedSurveyLanguage, params],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.PagedResult<Eezynet.Organisation>>(ApiRootUrl + URL, { params });
			return data;
		},
		enabled: enabled && !!surveyId,
	});
}

// function that gets the organisation of an survey from the api and returns it
export function useGetSurveyOrganisation(surveyid?: number, organisationid?: number, enabled: boolean = true) {
	const { selectedSurveyLanguage } = useAppContext();
	enabled = enabled && !!surveyid && !!organisationid;
	const axiosInstance = useAxiosContext();
	const URL = enabled ? pathToUrl(apiRoutes.getOrganisationById, { surveyid: surveyid, organisationid: organisationid }) : apiRoutes.getOrganisationById;
	return useQuery<Eezynet.Organisation, AxiosError>({
		queryKey: [URL, selectedSurveyLanguage],
		queryFn: async () => {
			const { data } = await axiosInstance.get<Eezynet.Organisation>(ApiRootUrl + URL);
			const convertedData = new Eezynet.Organisation(data);
			return convertedData;
		},
		enabled: enabled && !!surveyid && !!organisationid,
	});
}

export const getUsergroupPermissions = (axiosInstance: AxiosInstance, surveyId?: number, usergroupId?: number) => {
	const URL = pathToUrl(apiRoutes.getUsergroupPermissions, { surveyid: surveyId });
	const params = {
		usergroupid: usergroupId?.toString(),
	};
	return axiosInstance.get<Eezynet.UsergroupPermission[]>(ApiRootUrl + URL, { params }).then((response) => {
		return response.data.map((permission) => {
			return new Eezynet.UsergroupPermission(permission);
		});
	});
};

export const SaveUsergroup = (axiosInstance: AxiosInstance, surveyId?: number, usergroup?: Eezynet.Usergroup) => {
	const URL = pathToUrl(apiRoutes.saveUsergroup, { surveyid: surveyId });

	return axiosInstance.post<number>(ApiRootUrl + URL, usergroup).then((response) => {
		return response.data;
	});
};
