import { createContext, useContext, useEffect, useRef } from "react";
import _ from "lodash";
import { Eezynet } from "../models/Eezynet";

/**
 * Returns a randon integer between min and max
 * @returns {number}
 */
export const randomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min;

export const randomMaxInt = (): number => randomInt(0, 2147483647); // NOTE: 2147483647 is the maximum value for a 32-bit signed integer that is used in the C# code in the backend. that is why Number.MAX_SAFE_INTEGER is not used here.

/**
 * Returns a random string that can be used as an Id
 * @returns {string} random string of length 10
 */
export const GetRandomId = (): string => {
	return Math.random().toString(36).substring(2, 9);
};

/**
 * Returns the given font size (as string in px or rem) in pixels.
 */
export const GetFontSizeInPixels = (fontSize: string): number | undefined => {
	if (fontSize) {
		if (fontSize.endsWith("px")) {
			return parseFloat(fontSize.substring(0, fontSize.length - 2));
		}
		if (fontSize.endsWith("rem")) {
			return parseFloat(fontSize.substring(0, fontSize.length - 3)) * 16;
		}
		return parseFloat(fontSize);
	}
	return undefined;
};

export function isNumeric(n: number | null) {
	return n !== null && !isNaN(n) && isFinite(n);
}

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<T extends {} | null>() {
	const ctx = createContext<T | undefined>(undefined);
	function useCtx() {
		const c = useContext(ctx);
		if (c === undefined) {
			console.log("CREATE_CTX", ctx);
			throw new Error("useCtx must be inside a Provider with a value");
		}
		return c;
	}
	return [useCtx, ctx.Provider] as const; // 'as const' makes TypeScript infer a tuple
}

// returns the value of the property with the given name, case insensitive. If the property is not found, returns undefined.
export function getPropertyCaseInsensitive(obj: any, propertyName: string) {
	const keys = Object.keys(obj);
	const key = keys.find((k) => k.toLowerCase() === propertyName.toLowerCase());
	if (typeof key === "undefined") return undefined;
	return obj[key];
}

export const jsonStringifyWithoutCircular = (value: any, space?: string | number | undefined, showFunctions: boolean = false) => {
	const getCircularReplacer = () => {
		const seen = new WeakSet();
		return (key: string, value: any) => {
			if (typeof value === "object" && value !== null) {
				if (seen.has(value)) {
					return "[Circular]";
				}
				seen.add(value);
			}
			if (typeof value === "function" && showFunctions) {
				return value
					.toString()
					.replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, "") // remove comments
					.replace(/[\r\n]/g, "") // remove line breaks
					.replace(/\s+/g, " "); // replace multiple spaces with single space
			}
			// check if is a Map
			if (value instanceof Map) {
				return [...value];
			}
			return value;
		};
	};
	return JSON.stringify(value, getCircularReplacer(), space);
};

// rounds the given value to the given number of significant figures
export function roundUpToSignificantFigures(number: number, significanFigures: number) {
	if (number === 0) return 0;
	const magnitude = Math.floor(Math.log10(Math.abs(number)));
	const scale = Math.pow(10, magnitude - significanFigures + 1);
	return Math.ceil(number / scale) * scale;
}

// rounds the given value to the given number of decimals, but only if the number of decimals is greater than the number of decimals in the value
export const roundToMaxDecimals = (value: number, maxDecimals: number) => {
	const numDecimals = (value.toString().split(".")[1] || "").length;
	return numDecimals > maxDecimals ? Math.round(value * Math.pow(10, maxDecimals)) / Math.pow(10, maxDecimals) : value;
};

// returns the min and max values of the given results, rounded to the nearest significant figure
export const getScaleMinMax = (results: Eezynet.QuestionResultNumeric[], significanFigures: number = 1) => {
	const { minAverage, maxAverage } = results.reduce(
		(acc, result) => ({
			minAverage: Math.min(acc.minAverage, result.average ?? 0),
			maxAverage: Math.max(acc.maxAverage, result.average ?? 0),
		}),
		{ minAverage: Infinity, maxAverage: -Infinity },
	);

	const maxAbsolute = Math.max(Math.abs(minAverage), Math.abs(maxAverage));
	const roundedMax = roundUpToSignificantFigures(maxAbsolute, significanFigures);
	return { scaleMinValue: -roundedMax, scaleMaxValue: roundedMax };
};

// creates a hash from given text
// original name: "cyrb53" source: https://stackoverflow.com/a/52171480/963109
export const createNumericHash = (str: string, seed = 0) => {
	let h1 = 0xdeadbeef ^ seed,
		h2 = 0x41c6ce57 ^ seed;
	for (let i = 0, ch; i < str.length; i++) {
		ch = str.charCodeAt(i);
		h1 = Math.imul(h1 ^ ch, 2654435761);
		h2 = Math.imul(h2 ^ ch, 1597334677);
	}
	h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
	h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
	h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
	h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

	return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

async function sha256(input: string): Promise<string> {
	const buffer = new TextEncoder().encode(input);
	const digest = await crypto.subtle.digest("SHA-256", buffer);

	// Convert ArrayBuffer to Array
	const hashArray = Array.from(new Uint8Array(digest));

	// Convert bytes to hex string
	const hashHex = hashArray.map((b) => ("00" + b.toString(16)).slice(-2)).join("");
	return hashHex;
}

export const createStringHash = async (object: object): Promise<string> => {
	return await sha256(JSON.stringify(object));
};

export const simpleHash = (input: string | object): string => {
	const stringInput = typeof input === "string" ? input : JSON.stringify(input);

	let hash = 5381; // Initial prime number
	for (let i = 0; i < stringInput.length; i++) {
		hash = (hash * 33) ^ stringInput.charCodeAt(i);
	}

	// Convert the hash to a hexadecimal string
	return (hash >>> 0).toString(16);
};

// Returns the only the firstName or initials from the given name
export const getName = ({
	name = "",
	onlyFirstname = false,
	onlyInitials = false,
}: {
	name?: string;
	onlyFirstname?: boolean;
	onlyInitials?: boolean;
}): string => {
	let displayName = "";

	if (name) {
		if (onlyFirstname) {
			displayName = name.split(" ")[0];
		} else {
			displayName = name;
		}
	}

	if (onlyInitials) {
		displayName = displayName
			.split(" ")
			.map((x) => x[0]?.toUpperCase())
			.join("");
	}

	return displayName;
};

/**
 * Returns a string representation of the entries in an enum object.
 * @param enumObject - The enum object to get entries from.
 * @returns A string containing the enum entries in the format "value=key, value=key, ...".
 */
export function getEnumEntries(enumObject: any): string {
	return Object.entries(enumObject)
		.filter(([key]) => isNaN(Number(key))) // Filter out the numeric keys
		.map(([key, value]) => `${value}=${key}`)
		.join(", ");
}

export const isArrayOf =
	<T>(elemGuard: (x: any) => x is T) =>
	(arr: any[]): arr is Array<T> =>
		arr.every(elemGuard);

// Checks if the given string represents a positive integer.
// @param str - The string to check.
// @returns true if the string represents a positive integer, false otherwise.
export function isPositiveInteger(str: string | undefined): boolean {
	if (!str) return false;
	const num = parseInt(str, 10); // Parse the string to an integer, base 10
	// Check if it's a number, is an integer, and is greater than 0
	return !isNaN(num) && num > 0 && Number.isInteger(num);
}

/**
 * Capitalizes the first letter of a string.
 * example: capitalizeFirstLetter("hello") => "Hello"
 */
export const capitalizeFirstLetter = (input: string, locale?: string): string => {
	if (!input) return "";
	const first = input[0].toLocaleUpperCase(locale);
	const rest = input.slice(1);
	return first + rest;
};

/**
 * Converts the first letter of a string to lowercase.
 * @param input - The input string.
 * @param locale - The locale to use for case conversion. (optional)
 * @returns The input string with the first letter converted to lowercase.
 */
export const lowercaseFirstLetter = (input: string, locale?: string): string => {
	if (!input) return "";
	const first = input[0].toLocaleLowerCase(locale);
	const rest = input.slice(1);
	return first + rest;
};

/**
 * Gets string and returns true if it is a valid email address.
 * @param email - The email to check.
 * @returns true if the string is a valid email address, false otherwise.
 **/
export const isValidEmail = (email: string): boolean => {
	// Seuraava Cixtran k�ytt�m�n regex antaa varoituksia.
	//const emailRegex = new RegExp(
	//    '^([a-zA-Z0-9_-.]+)@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.)|(([a-zA-Z0-9-]+.)+))([a-zA-Z]{2,}|[0-9]{1,3})(]?)$'
	//);
	const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$/;
	return emailRegex.test(email);
};

/**
 * This function can be used to log the changes in props in a component.
 * @param props - The props of the component.
 * @returns void
 * @example
 * const MyComponent = (props: MyComponentProps) => {
 *    useTraceUpdate(props);
 *   return <div>...</div>
 * }
 * */
export const useTraceUpdate = (props: Record<string, any>): void => {
	const prev = useRef<Record<string, any>>(props);

	useEffect(() => {
		const changedProps: Record<string, [any, any]> = Object.entries(props).reduce(
			(ps, [k, v]) => {
				if (prev.current[k] !== v) {
					ps[k] = [prev.current[k], v];
				}
				return ps;
			},
			{} as Record<string, [any, any]>,
		);

		if (Object.keys(changedProps).length > 0) {
			console.log("Changed props:", changedProps);
		}
		prev.current = props;
	});
};

/**
 * Returns a simplified object with only the id, name, and ordinal properties.
 * @param obj - The object to simplify.
 * @returns An object with only the id, name, and ordinal properties.
 */
export const simplifyObject = (obj: any) => {
	return { id: obj?.id, name: obj?.name, ordinal: obj?.ordinal };
};

/**
 * Removes HTML tags from a string and returns the resulting plain text.
 * @param value - The string containing HTML tags to be removed.
 * @returns The plain text without HTML tags.
 */
export const clearHtml = (value: string) => {
	const tempElement = document.createElement("div");
	tempElement.innerHTML = value;
	var tempName = tempElement.textContent || tempElement.innerText || "";
	return tempName.trim();
};

type OmitKeys<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * Returns a new object with specified keys omitted (=removed).
 * @template T - The type of the input object.
 * @param {T} obj - The input object.
 * @param {Array<keyof T>} keysToOmit - The keys to omit from the input object.
 * @returns {OmitKeys<T, (typeof keysToOmit)[number]>} - A new object with specified keys omitted.
 */
export function omitKeys<T extends Record<string, any>>(obj: T, keysToOmit: Array<keyof T>): OmitKeys<T, (typeof keysToOmit)[number]> {
	const result: Partial<T> = {};
	for (const [key, value] of Object.entries(obj)) {
		if (!keysToOmit.includes(key as keyof T)) {
			result[key as keyof T] = value as T[keyof T];
		}
	}
	return result as OmitKeys<T, (typeof keysToOmit)[number]>;
}

/**
 * Returns the key of a given value in an enum. It is used to resolve the names of the values in the enum.
 */
export const findEnumKeyByValue = (enumeration: any, value: number | string): string | undefined => {
	return Object.keys(enumeration).find((key) => enumeration[key] === value);
};

export const convertStringToEnum = <T extends object>(enumObj: T, value: string): T[keyof T] | undefined => {
	const keys = Object.keys(enumObj).filter((key) => typeof enumObj[key as keyof T] === "string");
	const foundKey = keys.find((key) => enumObj[key as keyof T] === value);
	return foundKey !== undefined ? enumObj[foundKey as keyof T] : undefined;
};

/**
 * Removes duplicates from an array based on a provided identifier function.
 * @param array - The array from which duplicates will be removed.
 * @param identifierFn - A function that returns the identifier for each item in the array.
 * @returns A new array with duplicates removed.
 * @example
 * const users = [
 *   { id: 1, name: 'John' },
 *   { id: 2, name: 'Jane' },
 *   { id: 3, name: 'John' },
 *   { id: 4, name: 'Jane' }
 * ];
 *
 * const uniqueUsers = removeDuplicates(users, (user) => user.name);
 * // uniqueUsers will be:
 * // [
 * //   { id: 1, name: 'John' },
 * //   { id: 2, name: 'Jane' }
 * // ]
 */
export const removeDuplicates = <T>(array: T[], identifierFn: (item: T) => string): T[] => {
	const unique: T[] = [];
	const identifiers = new Set<string>();

	array.forEach((item) => {
		const identifier = identifierFn(item);
		if (!identifiers.has(identifier)) {
			identifiers.add(identifier);
			unique.push(item);
		}
	});
	return unique;
};

/**
 * Groups an array of elements by a specified key.
 *
 * @template T - The type of elements in the array.
 * @param {T[]} array - The array of elements to be grouped.
 * @param {(item: T) => string} keyGetter - The function that extracts the key from each element.
 * @returns {Record<string, T[]>} - An object where the keys are the extracted values and the values are arrays of elements with the same key.
 */
export const groupBy = <T>(array: T[], keyGetter: (item: T) => string): Record<string, T[]> => {
	const result: Record<string, T[]> = {};
	array.forEach((item) => {
		const key = keyGetter(item);
		if (!result[key]) {
			result[key] = [];
		}
		result[key].push(item);
	});
	return result;
};

/**
 * Checks if two values are deeply equal.
 *
 * @param a - The first value to compare.
 * @param b - The second value to compare.
 * @returns Returns `true` if the values are deeply equal, `false` otherwise.
 */
export const deepEqual = (a: any, b: any, visited: Set<any> = new Set()): boolean => {
	if (a === b) return true;

	if (typeof a !== "object" || a === null || typeof b !== "object" || b === null) {
		return false;
	}

	if (visited.has(a) || visited.has(b)) {
		return false;
	}

	visited.add(a);
	visited.add(b);

	const keysA = Object.keys(a);
	const keysB = Object.keys(b);

	if (keysA.length !== keysB.length) return false;

	for (const key of keysA) {
		if (!keysB.includes(key) || !deepEqual(a[key], b[key], visited)) {
			return false;
		}
	}

	visited.delete(a);
	visited.delete(b);

	return true;
};

export const truncateString = (str: string, maxLength: number, ellipsis: string = "..."): string => {
	if (str.length <= maxLength) return str;
	return str.substring(0, maxLength - ellipsis.length) + ellipsis;
};

// Generic function to compare properties between two objects of the same type
export const compareProperties = <T>(objA: T, objB: T, properties: (keyof T)[]): boolean => {
	for (const property of properties) {
		const valueA = objA[property];
		const valueB = objB[property];

		// Use lodash isEqual for deep comparison if values are objects
		if (!_.isEqual(valueA, valueB)) {
			return false;
		}
	}

	return true;
};

// Generic function to extract specified properties from any object
export const extractProperties = <T extends object, K extends keyof T>(obj: T, properties: K[]): Partial<T> => {
	const result: Partial<T> = {};

	for (const property of properties) {
		if (property in obj) {
			result[property] = obj[property];
		}
	}

	return result;
};

export const areArraysEqual = <T>(a: T[] | undefined, b: T[] | undefined): boolean => {
	if (a === undefined && b === undefined) return true;
	if (a === undefined && b?.length === 0) return true;
	if (a?.length === 0 && b === undefined) return true;
	if (a?.length !== b?.length) return false;
	return JSON.stringify(a) === JSON.stringify(b);
};
